import { useEffect, useState } from "react";
import Cookies from 'js-cookie';
import Login from './views/login';
import Setup from './components/SetupCode';
import ViewApp from './views/App';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        if (Cookies.get('code'))
            setIsLoggedIn(true);

        setIsLoading(false);
    }, []);

    if (isLoading)
        return <div />;

    if (!isLoggedIn)
        return (
            <Router>
                <Switch>
                    <Route path="/setup/:slug">
                        <Setup />
                    </Route>
                    <Route path="/">
                        <Login />
                    </Route>
                    <Route path="*">
                        <Login />
                    </Route>
                </Switch>
            </Router>
        );

    return (
        <Router>
            <Switch>
                <Route path="/dashboard">
                    <ViewApp />
                </Route>
                <Route path="/setup/:slug">
                    <Setup />
                </Route>
                <Route path="/">
                    <Login />
                </Route>
            </Switch>
        </Router>
    );
};

export default App;